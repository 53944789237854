import React from "react";
import "../theme/referral.less";
import Referral from "../components/Referral";
import { useAuth } from "../hooks/useAuth";
import { AuthContext } from "../context/AuthContext";

const ReferralPage = () => {
  const { mentor, mentorData, token, user, login, logout } = useAuth();

  console.log({ token });

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        mentor,
        mentorData,
        user,
        login,
        logout,
      }}
    >
      <Referral />
    </AuthContext.Provider>
  );
};

export default ReferralPage;
